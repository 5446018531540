import {
  isRouteErrorResponse,
  Link,
  useParams,
  useRouteError
} from '@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { FlexColumn } from '~/components/atoms/container';
import { OwlIcon } from '~/components/icons/owl';

export function CustomErrorBoundary() {
  let error = useRouteError();
  let params = useParams();
  let messages: Record<number, string> = {
    404: "Sorry we couldn't find what you're looking for",
    403: 'You are not authorized to access this resource'
  };
  let links: Record<number, string> = {
    404: `/`,
    403: `/`
  };
  let actions: Record<number, string> = {
    404: 'Back Home',
    403: 'Back Home'
  };

  let message = 'Oops! Looks like something unexpected happened.';
  let link = params?.chat
    ? `/${params?.account}/chats/${params?.chat}`
    : `/${params?.account}`;
  let action = 'Reload';

  if (isRouteErrorResponse(error)) {
    message = messages[error.status];
    link = links[error.status];
    action = actions[error.status];
  }

  // captureRemixErrorBoundaryError(error);
  console.log(error);

  return (
    <FlexColumn style={{ flex: 1, paddingTop: 64 }} alignment="center">
      <OwlIcon width={64} height={64} />
      <h1
        style={{ fontSize: 20, fontWeight: 600, marginTop: 24 }}
        children={message}
      />
      <p>Our team has been notified and are investigating the issue.</p>
      <Link
        className="btn btn__clip btn--secondary"
        to={link}
        style={{ marginTop: 16, textTransform: 'uppercase' }}
        children={action}
      />
    </FlexColumn>
  );
}
